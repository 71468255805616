import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    arr: [],
    index: '',
    src: ''
  },
  mutations: {
    srcfn (state, data) {
      state.src = data
    },
    storage (state, data) {
      state.arr = data
    },
    cutType (state, data) {
      switch (data) {
        case 'knowledge':
          state.index = '1'
          break
        case 'policy':
          state.index = '4'
          break
        case 'notice':
          state.index = '6'
          break
        case 'substratum':
          state.index = '7'
          break
      }
    }
  },
  actions: {
    cutType ({ commit }, data) {
      commit('detailVal', data)
    },
    storage ({ commit }, data) {
      commit('storage', data)
    }
  },
  modules: {
  }
})
