var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"knowledge"},[_c('div',{staticClass:"knowledge_box"},[_c('div',{staticClass:"knowledge_head"},[_c('div',{staticClass:"knowledge_head_box"},[_c('vxe-button',{staticStyle:{"margin":"0 0 18px 0","background":"#00706b","color":"white"},attrs:{"icon":"fa fa-plus"},on:{"click":function($event){return _vm.insertEvent()}}},[_vm._v("新增")])],1)]),_c('div',{staticClass:"knowledge_body"},[_c('vxe-table',{ref:"xTable",attrs:{"resizable":"","show-overflow":"","keep-source":"","align":"center","loading":_vm.loading,"data":_vm.tableData,"edit-config":{trigger: 'manual', mode: 'row', autoClear: false, showStatus: true}}},[_c('vxe-table-column',{attrs:{"field":"id","width":"200","title":"id"}}),_c('vxe-table-column',{attrs:{"field":"sortNo","title":"排序","width":"80","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text","placeholder":"请输入序号"},model:{value:(row.sortNo),callback:function ($$v) {_vm.$set(row, "sortNo", $$v)},expression:"row.sortNo"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"content","title":"公告内容","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text","placeholder":"请输入昵称"},model:{value:(row.content),callback:function ($$v) {_vm.$set(row, "content", $$v)},expression:"row.content"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"createTime","width":"200","title":"创建时间"}}),_c('vxe-table-column',{attrs:{"field":"releaseStatus","title":"上下架状态","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.releaseStatus))?_c('span',{staticClass:"grounding"},[_vm._v("上架")]):_c('span',{staticClass:"undercarriage"},[_vm._v("下架")])]}}])}),_c('vxe-table-column',{attrs:{"title":"操作","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.$refs.xTable.isActiveByRow(row))?[_c('vxe-button',{on:{"click":function($event){return _vm.saveRowEvent(row)}}},[_vm._v("保存")])]:[_c('vxe-button',{on:{"click":function($event){return _vm.editRowEvent(row)}}},[_vm._v("编辑")]),_c('vxe-button',{attrs:{"status":"warning","content":Number(row.releaseStatus) ? '下架' : '上架'},on:{"click":function($event){return _vm.cutReleaseStatus(row)}}}),_c('vxe-button',{attrs:{"status":"danger"},on:{"click":function($event){return _vm.removeNotice(row)}}},[_vm._v("删除")])]]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }