export default {
    // 计算两个时间的相差天数或小时或分钟
    calculateRemainingTime(startDate, endDate) {
        // 将日期对象转换为毫秒数
        const startMillis = startDate.getTime();
        const endMillis = endDate.getTime();

        // 计算两个日期之间的毫秒数差值
        const timeDifference = endMillis - startMillis;

        // 计算剩余天数
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // 计算剩余小时
        const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        // 计算剩余分钟
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        if (daysDifference > 1) {
            return `${daysDifference}天`;
        } else if (hoursDifference >= 1) {
            return `${hoursDifference}小时`;
        } else if (minutesDifference >= 1) {
            return `${minutesDifference}分钟`;
        } else {
            return '1分钟';
        }
    },
}