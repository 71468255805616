<template>
  <!-- 用户管理页面 -->
  <div class="knowledge">
    <div class="knowledge_box">
      <div class="knowledge_head1">
        <vxe-form :data="form">
          <vxe-form-item title="账户id" field="id" :item-render="{}">
            <template #default="{ data }">
              <vxe-input
                v-model="data.id"
                placeholder="请输入账户id"
                clearable
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item title="邮箱" field="email" :item-render="{}">
            <template #default="{ data }">
              <vxe-input
                v-model="data.email"
                placeholder="请输入邮箱"
                clearable
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item title="会员等级" field="roleCode" :item-render="{}">
            <template #default="{ data }">
              <vxe-select
                v-model="data.roleCode"
                placeholder="请选择会员等级查询"
              >
                <vxe-option value="" label="all"></vxe-option>
                <vxe-option
                  v-for="(item, index) in roleList"
                  :key="index"
                  :value="item.roleCode"
                  :label="item.roleName"
                ></vxe-option>
              </vxe-select>
            </template>
          </vxe-form-item>

          <vxe-form-item>
            <template #default>
              <vxe-button
                type="submit"
                status="primary"
                @click="getAllApp"
                content="查询"
              ></vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </div>
      <div class="knowledge_body">
        <vxe-table
          resizable
          show-overflow
          keep-source
          align="center"
          ref="xTable"
          :loading="loading"
          :data="tableData"
          :edit-config="{ trigger: 'manual', mode: 'row' }"
        >
          <vxe-table-column
            type="seq"
            width="50"
            title="序号"
            fixed="left"
          ></vxe-table-column>
          <vxe-table-column
            field="id"
            width="150"
            title="用户id"
          ></vxe-table-column>
          <vxe-table-column
            field="email"
            width="200"
            title="邮箱"
          ></vxe-table-column>

          <vxe-table-column field="releaseStatus" title="会员等级" width="100">
            <template #default="{ row }">
              <span v-if="row.roleCode == 'general'" style="color: #bfbfbf"
                >普通会员</span
              >
              <span
                v-if="row.roleCode == 'gold'"
                style="
                  background: #f4ea2a;
                  color: white;
                  padding: 5px;
                  font-weight: 500;
                "
                >黄金会员</span
              >
              <span
                v-if="row.roleCode == 'platinum'"
                style="
                  background: #1296db;
                  color: white;
                  padding: 5px;
                  font-weight: bold;
                "
                >铂金会员</span
              >
            </template>
          </vxe-table-column>

          <vxe-table-column
            field="timeoutTime"
            width="200"
            title="会员到期时间"
          ></vxe-table-column>

          <vxe-table-column
            field="effectDay"
            width="120"
            title="会员有效天数"
          >
            <template #default="{ row }">
              <span>{{ row.effectDay ? row.effectDay + '天' : '' }}</span>
            </template>
          </vxe-table-column>

          <vxe-table-column
            field="createTime"
            width="200"
            title="用户注册时间"
          ></vxe-table-column>

          <!-- <vxe-table-column
            field="inviterId"
            width="200"
            title="邀请人id"
          ></vxe-table-column>

          <vxe-table-column
            field="inviterEmail"
            width="200"
            title="邀请人邮箱"
          ></vxe-table-column> -->

          <vxe-table-column title="操作" minWidth="300" fixed="right">
            <template #default="{ row }">


              <el-popover
                placement="left"
                min-width="200"
                trigger="click"
              >
                <div class="top_up_select_ul" >
                    <div v-for="(item, index) in rechargeLevelList" :key="index">
                        <!-- <p class="top_up_select_ul_title">{{ item.levelCode }}</p> -->
                        <div class="top_up_select_ul_text" :key="index" @click="topUp(row, item)">{{ item.levelCode }}</div>
                    </div>
                </div>
                
                <el-button type="success" size="mini" style="margin: 0 10px 0 0" slot="reference">充值</el-button>
              </el-popover>
              <el-button type="primary" size="mini" @click="topUpRecord(row)">充值记录</el-button>
              <!-- <el-button type="warning" size="mini" @click="topUpRecord(row)">退款记录</el-button> -->
              <el-button type="danger" size="mini" @click="resetPass(row)">重置密码</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
        <vxe-pager
          background
          :loading="loading"
          :current-page.sync="currentPage"
          :page-size.sync="currentSize"
          :total="total"
          @page-change="handlePageChange"
          :layouts="[
            'PrevJump',
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'NextJump',
            'Sizes',
            'FullJump',
            'Total',
          ]"
        >
        </vxe-pager>
      </div>
      <vxe-modal v-model="recordModal" width="1200">
        <template #title>
          <span>充值记录</span>
        </template>
        <template #corner>
          <vxe-icon name="bell-fill"></vxe-icon>
          <vxe-icon name="minus"></vxe-icon>
        </template>
        <template #default>
          <vxe-table
            show-overflow
            height="300"
            :loading="recordLoading"
            :sync-resize="recordModal"
            :data="recordData"
          >
            <vxe-column type="seq" title="序号" width="60"></vxe-column>
            <vxe-column field="id" title="充值记录id"></vxe-column>
            <vxe-column field="accountId" title="用户id"></vxe-column>
            <vxe-column
              field="rechargeLevelCode"
              title="充值档位编码"
              width="120"
            ></vxe-column>
            <vxe-column field="rechargeMoney" title="充值金额" width="100"></vxe-column>
            <vxe-column field="effectDay" title="有效天数" width="100">
              <template #default="{ row }">
                <span>{{ row.effectDay ? row.effectDay + '天' : '' }}</span>
              </template>
            </vxe-column>
            <vxe-column field="timeoutStatus" title="过期状态">
                <template #default="{ row }">
                    <span>{{ row.timeoutStatus != null || row.timeoutStatus != undefined ? Number(row.timeoutStatus) ? '过期' : '未过期' : '' }}</span>
                </template>
            </vxe-column>
            <vxe-column field="rechargeTime" title="充值时间"></vxe-column>
            <vxe-column field="timeoutTime" title="过期时间"></vxe-column>
            
          </vxe-table>
          <vxe-pager
            background
            :loading="recordLoading"
            :current-page.sync="recordPaging.currentPage"
            :page-size.sync="recordPaging.currentSize"
            :total="recordPaging.total"
            @page-change="pagingHandlePageChange"
            :layouts="[
              'PrevJump',
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'NextJump',
              'Sizes',
              'FullJump',
              'Total',
            ]"
          >
          </vxe-pager>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>
  
<script>
import API from "../service/api";
import XEUtils from "xe-utils";
export default {
  name: "hotWord",
  data() {
    return {
      form: {
        id: "",
        email: "",
        roleCode: "",
      },

      loading: false,
      tableData: [],
      roleList: [],
      currentPage: 1,
      currentSize: 10,
      total: 0,
      recordModal: false,
      recordPaging: {
        currentPage: 1,
        currentSize: 10,
        total: 0,
      },
      recordLoading: false,
      recordData: [],
      recordId: "",
      rechargeLevelList: [],
    };
  },
  watch: {
    // 监听充值弹窗是否关闭，关闭后还原充值分页条数和总数
    recordModal: {
      handler(flag) {
        if (!flag) {
          this.recordPaging.recordPaging = 1;
          this.recordPaging.currentSize = 10;
          this.recordPaging.total = 0;
        }
      },
    },
  },
  created() {
    this.getAllApp();
    this.getRoleList();
    // 获取充值挡位
    this.getRechargeLevelList();
  },
  methods: {
    // 获取充值挡位
    getRechargeLevelList() {
      const _this = this;
      API.getRechargeLevelList().then((res) => {
        if (res.code == 200) {
          _this.rechargeLevelList = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    // 充值记录表格分页
    pagingHandlePageChange({ currentPage, pageSize }) {
      this.recordPaging.currentPage = currentPage;
      this.recordPaging.currentSize = pageSize;
      this.getRecordList();
    },
    // 充值
    topUp(row, item) {
        const _this = this
        console.log(row, item)
        let str, name = item.levelCode.indexOf('GPT4') != -1 ? '铂金会员' : '黄金会员'
        if(row.roleName != '普通会员') {
            console.log(_this.$utils.calculateRemainingTime(new Date(),new Date(row.memberTime)))
            str = `当前<span style='color:red'>“${row.email}”</span>用户已是<span style='color:red'>“${row.roleName}”</span>，剩余会员天数<span style='color:red'>“${_this.$utils.calculateRemainingTime(new Date(),new Date(row.memberTime))}”</span>，是否继续追加<span style='color:red'>“${name}${item.levelName}”</span>`
        } else {
            str = `确定给<span style='color:red'>“${row.email}”</span>用户充值<span style='color:red'>“${name}${item.levelName}”</span>, 是否继续?`
        }
        _this.$confirm(
          str,
          "充值提示",
          {
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          API.setRechargeRecordInsert({ accountId: row.id, rechargeLevelCode: item.levelCode, rechargeMoney: item.price }).then((res) => {
            console.log(res)
            if (res.code == 200) {
              _this.getAllApp();
              _this.$message.success("充值成功");
            } else {
              _this.$message.error(res.msg);
            }
          });
        })
        .catch(() => _this.$message.info(`已取消充值“${row.email}”`));
    },
    // 充值记录
    topUpRecord(row) {
      this.recordModal = true;
      this.recordData = []
      // 存储打开充值记录的账户id，查看充值记录表格页分页时使用
      this.recordId = row.id;
      this.getRecordList();
    },
    // 获取充值记录列表
    getRecordList() {
      const _this = this;
      _this.recordLoading = true
      API.rechargeRecordPage({
        accountId: this.recordId,
        currentPage: _this.recordPaging.currentPage,
        currentSize: _this.recordPaging.currentSize,
      }).then((res) => {
        if (res.code == 200) {
          _this.recordData = res.data.records;
          _this.recordPaging.total = res.data.totalRow;
        } else {
          _this.$message.error(res.msg);
        }
        _this.recordLoading = false
      }).catch(err => {
        _this.recordLoading = false
        _this.$message.error(err);
      })
    },
    // 重置密码
    resetPass(row) {
      const _this = this;
      _this
        .$confirm(
          `确定重置“${row.email}”账号的密码？, 是否继续?`,
          "重置密码提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
          }
        )
        .then(() => {
          API.resetPass({ accountId: row.id }).then((res) => {
            if (res.code == 200) {
              _this.getAllApp();
              _this.$message.success("重置成功");
            } else {
              _this.$message.error(res.msg);
            }
          });
        })
        .catch(() => _this.$message.info(`已取消重置“${row.id}”`));
    },
    // 获取角色列表
    getRoleList() {
      const _this = this;
      API.getRoleList().then((res) => {
        if (res.code == 200) {
          _this.roleList = res.data;
        } else {
          _this.$message.error("获取会员等级列表失败：", res.msg);
        }
      });
    },
    // 获取用户列表
    getAllApp() {
      const _this = this;
      _this.loading = true;
      API.getAccountPage({
        accountId: this.form.id ? this.form.id : null, // this.form.id
        email: this.form.email ? this.form.email : null, // this.form.email
        roleCode: this.form.roleCode ? this.form.roleCode : null,
        currentPage: this.currentPage,
        currentSize: this.currentSize,
      })
        .then((res) => {
          if (res.code == 200) {
            _this.tableData = res.data.records;
            _this.total = res.data.totalRow;
          } else {
            _this.$message.error(res.msg);
          }
          _this.loading = false;
        })
        .catch((err) => {
          _this.loading = false;
          _this.$message.error(err);
        });
    },
    // 表格切换分页
    handlePageChange({ currentPage, pageSize }) {
      this.currentPage = currentPage;
      this.currentSize = pageSize;
      this.getAllApp();
    },
  },
};
</script>
  
  <style scope>
.elDialog .el-dialog__header {
  background: white;
}
.el-popover {
  width: 203px !important;
  padding: 15px 15px 21px 20px !important;
}
.el-popover__title {
  margin: 5px 12px 20px 0 !important;
}
.el-dialog__footer {
  padding: 8px 20px 20px !important;
  text-align: right !important;
}
.iconSty {
  max-width: 45px;
  max-height: 45px;
  margin: 20px 0 0 15px;
}
.icon {
  max-width: 45px;
  max-height: 45px;
}
.knowledge {
  padding: 20px 0 0 20px;
}
.knowledge_box {
  width: 100%;
  background: #f0f2f5;
}
.knowledge_head1 {
  /* width: 100%; */
  height: 55px;
  /* padding: 17px 0 0 0; */
  padding: 17px 20px 14px;
  background: white;
  margin: 0 0 20px 0;
}
.knowledge_head_box {
  float: right;
  margin-right: 20px;
}
.knowledge_body {
  /* width: 100%; */
  /* height: 100px; */
  padding: 20px;
  background: white;
}
.top_up_select_ul {
    /* max-height: 200px; */
    /* background: red; */
}
.top_up_select_ul_title {
    font-size: 12px;
    color: #909399;
    line-height: 30px; 
    height: 30px;
}
.top_up_select_ul_text {
    padding: 0 10px;
    font-size: 14px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606266;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
}
.top_up_select_ul_text:hover {
    background-color: #f5f7fa;
}
</style>
  