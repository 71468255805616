<template>
  <!-- 报错日志统计 -->
  <div class="knowledge">
    <div class="knowledge_box">
      <div class="knowledge_head">
        <vxe-form :data="form">
          <vxe-form-item title="账户id" field="id" :item-render="{}">
            <template #default="{ data }">
              <vxe-input
                v-model="data.id"
                placeholder="请输入账户id"
                clearable
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item
            title="开始日期"
            field="date"
            span="5"
            :item-render="{}"
            title-overflow="ellipsis"
          >
            <template #default="{ data }">
              <vxe-input
                v-model="data.startTime"
                clearable
                type="date"
                placeholder="请选择开始日期"
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item
            title="结束日期"
            field="date"
            span="5"
            :item-render="{}"
            title-overflow="ellipsis"
          >
            <template #default="{ data }">
              <vxe-input
                v-model="data.endTime"
                clearable
                type="date"
                placeholder="请选择结束日期"
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item>
            <template #default>
              <vxe-button
                type="submit"
                status="primary"
                @click="getData"
                content="查询"
              ></vxe-button>
              <vxe-button
                type="submit"
                @click="reset"
                content="重置"
              ></vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </div>
      <div class="knowledge_body">
        <vxe-table
          :loading="loading"
          ref="xTable"
          auto-resize
          :data="tableData"
          @toggle-row-expand="toggleExpandChangeEvent"
        >
          <vxe-column type="expand" width="50" :fixed="expandFixed">
            <template #content="{ row }">
              <div class="expand-wrapper">
                <el-row>
                  <el-col :span="24">
                    <span><b>请求方式：</b></span>
                    <span>{{ row.requestMethod }}</span>
                  </el-col>
                  <!-- <el-col :span="15">
                    <span><b>请求url：</b></span>
                    <span>{{ row.requestUrl }}</span>
                  </el-col> -->

                  <el-col :span="24">
                    <div class="exception_message">
                        <div><b>请求参数：</b></div>
                        <div class="message" v-if="row.requestParams">{{ row.requestParams }}</div>
                    </div>
                  </el-col>

                  <el-col :span="24">
                    <div class="exception_message">
                        <div><b>异常标题：</b></div>
                        <div class="message" v-if="row.exceptionTitle">{{ row.exceptionTitle }}</div>
                    </div>
                  </el-col>

                  <el-col :span="24">
                    <div class="exception_message">
                        <div><b>异常信息：</b></div>
                        <div class="message" v-if="row.exceptionMessage">{{ row.exceptionMessage }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="id" width="220"  title="日志主键"></vxe-column>
          <vxe-column field="accountId" width="220"  title="账户id"></vxe-column>
          <vxe-column field="logSystem" width="110" title="日志来源">
            <template #default="{ row }">
              <span v-if="Number(row.logSystem)">官网</span>
              <span v-else>管理后台</span>
            </template>
          </vxe-column>
          <vxe-column field="requestUrl" title="请求url"></vxe-column>
          <vxe-column field="exceptionTime" title="异常时间"></vxe-column>
        </vxe-table>
        <vxe-pager
          background
          :loading="loading"
          :current-page.sync="currentPage"
          :page-size.sync="currentSize"
          :total="total"
          @page-change="handlePageChange"
          :layouts="[
            'PrevJump',
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'NextJump',
            'Sizes',
            'FullJump',
            'Total',
          ]"
        >
        </vxe-pager>

      </div>
    </div>
  </div>
</template>


<script>
import API from "../service/api";
export default {
  data() {
    return {
      form: {
        id: "",
        startTime: "",
        endTime: "",
      },
      currentPage: 1,
      currentSize: 10,
      total: 0,
      loading: false,
      seqFixed: null,
      expandFixed: null,
      ageFixed: null,
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
        this.form.id = ""
        this.form.startTime = ""
        this.form.endTime = ""
    },
    // toggleSeqFixed() {
    //   this.seqFixed = this.seqFixed ? null : "left";
    //   this.$nextTick(() => {
    //     this.$refs.xTable.refreshColumn();
    //   });
    // },
    // toggleExpandFixed() {
    //   this.expandFixed = this.expandFixed ? null : "left";
    //   this.$nextTick(() => {
    //     this.$refs.xTable.refreshColumn();
    //   });
    // },
    toggleExpandChangeEvent({ row, expanded }) {
    //   console.log("行展开事件" + expanded);
    },
    getData() {
      this.loading = true
      API.exceptionLogPage({
        accountId: this.form.id,
        currentPage: this.currentPage,
        currentSize: this.currentSize,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
      }).then((res) => {
        if (res.code == 200) {
            this.tableData = res.data.records && res.data.records.length ? res.data.records : []
            this.total = res.data.totalRow
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    // 表格分页
    handlePageChange({ currentPage, pageSize }) {
      this.currentPage = currentPage;
      this.currentSize = pageSize;
      this.getData();
    },
  },
};
</script>

<style scope>
.el-col {
  padding: 10px 0;
}
.expand-wrapper {
  padding: 20px;
}
.elDialog .el-dialog__header {
  background: white;
}
.el-popover {
  width: 203px !important;
  padding: 15px 15px 21px 20px !important;
}
.el-popover__title {
  margin: 5px 12px 20px 0 !important;
}
.el-dialog__footer {
  padding: 8px 20px 20px !important;
  text-align: right !important;
}
.knowledge {
  padding: 20px 0 0 20px;
}
.knowledge_box {
  width: 100%;
  background: #f0f2f5;
}
.knowledge_head {
  /* width: 100%; */
  height: 55px;
  padding: 17px 20px 14px !important;
  background: white;
  margin: 0 0 20px 0;
}
.knowledge_body {
  /* width: 100%; */
  /* height: 100px; */
  padding: 20px;
  background: white;
}
.exception_message {
    display: -webkit-box;
    width: 100%;
}
.exception_message .message {
    max-width: calc(100% - 70px);
    max-height: 500px;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    background: black;
    color: #b2b2b2;
    font-size: 16px;
    padding: 10px;
    line-height: 25px;
    border-radius: 5px;
}
</style>