<template>
  <div id="app">
    <div class="navigation">
      <div class="navigation_logo">
        <!-- <img src="./images/logo.png"> -->
      </div>
      <div class="logos_right">
        <span>{{time}}</span>
        <div class="logos_img">
          <img src="./images/touxiang.png">
          <i id='useName'>{{name}}</i>
          <span class="logout" @click="logout">点击退出</span>
          <!-- <img src="./images/xiala.png" class="xiala"> -->
        </div>
      </div>
    </div>
    <div class="content">
      <Home v-if="isHome"/>
    </div>
    <el-dialog
      title="登录"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%">

      <el-form label-width="50px" >
        <el-form-item label="账号:">
          <el-input placeholder="请输入账号" v-model="account" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
        </el-form-item>
      </el-form>

      <!-- 账号<el-input placeholder="请输入账号" v-model="account" clearable></el-input>
      
      密码<el-input placeholder="请输入密码" v-model="password" show-password></el-input> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="login">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Home from './views/Home.vue'
import API from './service/api'
export default {
  // name: 'App',  navigation_user
  components: {
	  Home
  },
  data () {
    return {
      time: '',
      name: '',
      dialogVisible: false, // 登录弹窗
      isHome: false,
      account: '', // 账号
      password: '', // 密码
      correctA: '666666',
      correctP: '12345qwert',
    }
  },
  mounted () {
    // var nowDate = new Date()
    // var noticeYY = nowDate.getFullYear()
    // var noticeMM = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
    // var noticeDD = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()
    // this.time = noticeYY + '-' + noticeMM + '-' + noticeDD

    // let time = sessionStorage.getItem('time')
    // // 判断是否登录
    // if(time != null && time != undefined && time != '') {
    //   // 判断是否超时
    //   if(new Date().getTime() < Number(time)) {
    //     // 当前时间小于记录时间，允许进行登录
    //     this.dialogVisible = false
    //     this.isHome = true
    //     return
    //   }
    // }
    if(localStorage.getItem('_t_o_k_e_n_')) {
      this.isHome = true
      this.name = JSON.parse(localStorage.getItem('_t_o_k_e_n_')).account
    } else {
      this.dialogVisible = true
    }
    // this.dialogVisible = true
  },
  methods: {
    login() {
      API.login({
        account: this.account,
					password: this.password,
				}).then(res => {
          if(res.code == 200) {
            localStorage.setItem('_t_o_k_e_n_', JSON.stringify(res.data))
            this.dialogVisible = false
            this.isHome = true
            this.name = JSON.parse(localStorage.getItem('_t_o_k_e_n_')).account
          } else {
            this.$message.error(res.msg);
          }
      }).catch(err => console.log(err))
      // if(this.account == this.correctA && this.password == this.correctP) {
      //   // 获取当前时间的时间戳
      //   var atPresentTime = new Date().getTime()
      //   // 获取N小时时间戳
      //   var futureTime = 1000 * 60 * 60 * window.public.loginTimeoutTime
      //   // 储存登录超时时间
      //   sessionStorage.setItem('time', atPresentTime + futureTime)
      //   // 登录窗口关闭
      //   this.dialogVisible = false
      //   // 页面加载
      //   this.isHome = true
      // } else this.$message.error('错了，直接pass。下一位！');
    },
    logout() {
      localStorage.removeItem('_t_o_k_e_n_')
      this.dialogVisible = true
      this.isHome = false
    },
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}
.navigation {
  width: 100%;
  height: 60px;
  background: #00706B;
}
.content {
  width: 100%;
}

.navigation_logo {
  padding-top: 9px;
  margin-left: 15px;
  float: left;
}
.logos_right {
  height: 100%;
  float: right;
  margin-right: 30px;
}
.logos_right span{
	color: #ffffff;
	font-size: 14px;
	line-height: 60px;
}
.logos_img{
	float: right;
	margin-left: 20px;
	height: 60px;
	/* cursor: pointer; */
}
.logos_img img{
	margin-top: 13px;
	float: left;
}
.logos_img i{
	display: block;
	float: left;
	line-height: 60px;
	color: #FFFFFF;
	margin-left: 10px;
	margin-right: 10px;
  font-style:normal
}
.logos_img .xiala{
	margin-top: 28px;
}
.logos_img .logout {
  margin: 0 0 0 10px;
  cursor: pointer;
}
</style>
