<template>
  <!-- 充值记录统计页面 -->
  <div class="knowledge">
    <div class="knowledge_box">
      <div class="knowledge_head1">
        <vxe-form :data="form">
          <vxe-form-item
            title="开始日期"
            field="date"
            span="5"
            :item-render="{}"
            title-overflow="ellipsis"
          >
            <template #default="{ data }">
              <vxe-input
                v-model="data.startTime"
                type="date"
                placeholder="请选择开始日期"
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item
            title="结束日期"
            field="date"
            span="5"
            :item-render="{}"
            title-overflow="ellipsis"
          >
            <template #default="{ data }">
              <vxe-input
                v-model="data.endTime"
                type="date"
                placeholder="请选择结束日期"
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item>
            <template #default>
              <vxe-button
                type="submit"
                status="primary"
                @click="getEchartData"
                content="查询"
              ></vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </div>

      <div class="knowledge_body">
        <el-row :gutter="24">
          <el-col :span="8">
            <div>
              <el-statistic
                group-separator=","
                :precision="2"
                :value="balance"
                title="总金额"
              >
                <template slot="prefix">
                  <img src="../images/balance.svg" alt="" />
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <el-statistic
                group-separator=","
                :precision="2"
                :value="recharge"
                title="累计充值金额"
              >
                <template slot="prefix">
                  <img src="../images/recharge.svg" alt="" />
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <el-statistic
                group-separator=","
                :precision="2"
                decimal-separator="."
                :value="refund"
                title="累计退款金额"
              >
                <template slot="prefix">
                  <img src="../images/refund.svg" alt="" />
                </template>
              </el-statistic>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <div class="charts" id="pie"></div>
          </el-col>
          <el-col :span="12">
            <div class="charts" id="bar"></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
  
<script>
import API from "../service/api";
import * as echarts from "echarts";
export default {
  data() {
    return {
      form: {
        startTime: "",
        endTime: "",
      },
      balance: 0, // 总金额
      recharge: 0, // 充值金额
      refund: 0, // 退款金额
    };
  },
  created() {
    // 获取7天前日期
    this.form.startTime = this.getDay(-6);
    // 获取当前日期
    this.form.endTime = this.getDay(0);
  },
  mounted() {
    this.getEchartData()
    // this.$nextTick(() => {
    //   this.createPieEchart();
    //   this.createBarEchart();
    // });
  },

  methods: {
    getEchartData() {
      const _this = this
      // 获取充值记录统计数据
      API.rechargeRecordStatistic({
        startTime: _this.form.startTime,
        endTime: _this.form.endTime,
      }).then((res) => {
        if (res.code == 200) {
          let data = res.data
          let name, rechargeMoney, refundMoney = []
          if(data.rechargeRecordStatisticDays && data.rechargeRecordStatisticDays.length) {
            name = data.rechargeRecordStatisticDays.map(el => el.time)
            rechargeMoney = data.rechargeRecordStatisticDays.map(el => el.rechargeMoney)
            refundMoney = data.rechargeRecordStatisticDays.map(el => el.refundMoney)
          }
          console.log(res)
          _this.balance = res.data.allMoney ? Number(res.data.allMoney) : 0
          _this.recharge = res.data.rechargeMoney ? Number(res.data.rechargeMoney) : 0
          _this.refund = res.data.refundMoney ? Number(res.data.refundMoney) : 0
          let array = [
            {name: '总金额', value: res.data.allMoney ? res.data.allMoney : 0 },
            {name: '累计充值', value: res.data.rechargeMoney ? res.data.rechargeMoney : 0 },
            {name: '累计退款', value: res.data.refundMoney ? res.data.refundMoney : 0 },
          ]
          // 饼图
          _this.createPieEchart(array)
          // 折线图
          _this.createBarEchart(name, rechargeMoney, refundMoney)
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    // 创建饼图图表
    createPieEchart(array) {
      var chartDom = document.getElementById("pie");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
          padding: [100, 0, 0, 20],
        },
        color: ['#1296db', '#1afa29', '#d81e06'],
        series: [
          {
            type: "pie",
            center: ["60%", "50%"],
            radius: "80%",
            data: array,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    // 创建折线图图表
    createBarEchart(name, rechargeMoney, refundMoney) {
      var chartDom = document.getElementById("bar");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ['#00ff00', '#ff0000'],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          padding: [20, 0, 0, 0],
          data: ["充值", "退款"],
        },
        grid: {
          top: 60,
          left: 50,
          bottom: 40,
          right: 25,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: name,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "充值",
            type: "line",
            data: rechargeMoney,
            symbolSize: name.length > 20 ? 6 : 8,
            z: 1
          },
          {
            name: "退款",
            type: "line",
            data: refundMoney,
            symbolSize: name.length > 20 ? 6 : 8,
            z: 0
          },
        ],
      };

      option && myChart.setOption(option);
    },
    //获取当前日期
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    //获取当前月份
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
  },
};
</script>
  
  <style scope>
.elDialog .el-dialog__header {
  background: white;
}
.el-popover {
  width: 203px !important;
  padding: 15px 15px 21px 20px !important;
}
.el-popover__title {
  margin: 5px 12px 20px 0 !important;
}
.el-dialog__footer {
  padding: 8px 20px 20px !important;
  text-align: right !important;
}
.iconSty {
  max-width: 45px;
  max-height: 45px;
  margin: 20px 0 0 15px;
}
.icon {
  max-width: 45px;
  max-height: 45px;
}
.knowledge {
  padding: 20px 0 0 20px;
}
.knowledge_box {
  width: 100%;
  background: #f0f2f5;
}
.knowledge_head1 {
  height: 55px;
  padding: 17px 20px 14px;
  background: white;
  margin: 0 0 20px 0;
}
.knowledge_head_box {
  float: right;
  margin-right: 20px;
}
.knowledge_body {
  padding: 20px;
  background: white;
}
.charts {
  margin: 50px 0 30px 0;
  height: 300px;
  /* background: red; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
</style>
  