import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import utils from './utils/common'
import '../public/config'
import 'element-ui/lib/theme-chalk/index.css'
// import 'xe-utils'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// Vue.use(VXETable)

Vue.prototype.$utils = utils



Vue.use(VXETable)
Vue.use(ElementUI)
Vue.config.productionTip = false

// if (typeof(FileReader.prototype.readAsBinaryString) !== "function") {
//   FileReader.prototype.readAsBinaryString = function (fileData) {
//       let binary = "";
//       let pt = this;
//       let reader = new FileReader();
//       reader.onload = function (e) {
//           let bytes = new Uint8Array(reader.result);
//           let length = bytes.byteLength;
//           for (let i = 0; i < length; i++) {
//               binary += String.fromCharCode(bytes[i]);
//           }
//           let obj = {};
//           obj.target = {result: binary};
//           pt.onload(obj);
//       }
//       reader.readAsArrayBuffer(fileData);
//   }
// }


// Vue.prototype.$base64Img = (e)=>{
//   return new Promise(function(resolve, reject) {      //使用Promise进行异步处理
//       let fileSize = 0;
//       let fileMaxSize = 1024;                 //设置图片最大为 1M 
//       fileSize = e.file.size;           //获取图片大小
//       let size = fileSize / 1024;
//       let file = e.file;           //获取图片文件对象
//       let reader = new FileReader();          //实例化一个对象
//       if (size > fileMaxSize) { 
//           // e.target.value  = ''                //内容清空
//           resolve({err:'图片大小必须小于1M！'})
//       }else if (size <= 0) { 
//           // e.target.value  = ''                //内容清空
//           resolve({err:'图片大小不能为0M！'})
//       }else{
//           reader.readAsDataURL(file);             //把图片文件对象转换base64
//           reader.onload = function(el) {
//                resolve(el.target.result)           //回到函数返回base64值
//           }  
//       }
//   })  
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
