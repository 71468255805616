<template>
  <div class="knowledge">
    <div class="knowledge_box">
        <div class="knowledge_head">
            <div class="knowledge_head_box">
              <vxe-button icon="fa fa-plus" style="margin: 0 0 18px 0;background:#00706b;color:white;" @click="insertEvent()">新增</vxe-button>
              <vxe-button @click="removeSele">删除</vxe-button>
            </div>
        </div>
        <div class="knowledge_body">
            <vxe-table
                resizable
                show-overflow
                keep-source
                align="center"
                ref="xTable"
                :loading="loading"
                :data="tableData"
                :edit-config="{trigger: 'manual', mode: 'row'}"
                >
                <vxe-table-column type="checkbox" width="40"></vxe-table-column>
                <vxe-table-column type="seq" width="50" title="序号"></vxe-table-column>
                <vxe-table-column
                    field="appId"
                    width="100"
                    title="系统编码"
                    :edit-render="{name: 'input', attrs: {type: 'text'}}"
                ></vxe-table-column>
                <vxe-table-column
                    field="appName"
                    title="应用名称"
                    :edit-render="{name: 'input', attrs: {type: 'text'}}"
                ></vxe-table-column>
                <vxe-table-column
                    field="appUrl"
                    title="应用地址"
                    :edit-render="{name: 'input', attrs: {type: 'text'}}"
                ></vxe-table-column>
                <vxe-table-column
                    field="busiCode"
                    title="ISC资源编码"
                    :edit-render="{name: 'input', attrs: {type: 'text'}}"
                ></vxe-table-column>
                <vxe-table-column
                    field="iscAppId"
                    title="ISC_APP_ID"
                    :edit-render="{name: 'input', attrs: {type: 'text'}}"
                ></vxe-table-column>
                <vxe-table-column
                    field="tempLogo"
                    title="应用图标"
                    type="html"
                ></vxe-table-column>
                <vxe-table-column title="操作" width="180">
                    <template #default="{ row }">
                    <template v-if="$refs.xTable.isActiveByRow(row)">
                        <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
                        <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>
                    </template>
                    <template v-else>
                        <vxe-button slot="reference" @click="cutObj(row)">{{row.appLogo ? '修改' : '上传'}}图标</vxe-button>
                        <vxe-button @click="editRowEvent(row)">编辑</vxe-button>

                    </template>
                    </template>
                </vxe-table-column>
                </vxe-table>
                <vxe-pager
                    border
                    size="medium"
                    :loading="loading"
                    :current-page="tablePage2.currentPage"
                    :page-size="tablePage2.pageSize"
                    :page-sizes="tablePage2.pageSizes"
                    :total="tablePage2.totalResult"
                    :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                    @page-change="handlePageChange2">
                </vxe-pager>
                <el-dialog
                v-if="dialogVisible"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                width="25%"
                class="elDialog"
                :show-close="false">
                    <el-upload
                        class="upload-demo"
                        style="margin-left:15px;"
                        action="string"
                        :http-request="customUploading"
                        :before-upload="beforeUpload"
                        :show-file-list="false"
                        :limit="1">
                            <el-button size="small">{{tempObj.appLogo ? '重新' : '点击'}}上传<i class="el-icon-upload el-icon--right"></i></el-button>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，建议42*42px</div>
                    </el-upload>
                    <img  class="iconSty" :src="tempObj.appLogo" alt="">
                    <span slot="footer" class="dialog-footer">
                    <vxe-button @click="confirmAdd">确定</vxe-button>
                    </span>
                </el-dialog>
        </div>
    </div>
  </div>
</template>

<script>
import API from '../service/api';
import XEUtils from 'xe-utils'
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      tableData1: [],
      tablePage2: {
        currentPage: 1,
        pageSize: 5,
        totalResult: 0,
        pageSizes: [5, 10, 15],
      },
      dialogVisible : false,
      tempObj: null,
    };
  },
  created() {
      this.getAllApp();
  },
  methods: {

    uploadFileEvent(row) {
      const $grid = this.$refs.xTable
      // 读取附件
      $grid.readFile({
        multiple: true,
        types: ['jpg', 'png']
      }).then(params => {
        const { files } = params
        // 解析数据并显示
        Array.from(files).forEach(file => {
          const ns = file.name.split('.')
          const name = ns.slice(0, ns.length - 1).join('')
          const type = ns[ns.length - 1]
          const record = {
            name: name,
            size: file.size,
            type: type,
            date: XEUtils.toDateString(new Date())
          }
          // row.fileList.push(record)
        })
        // 加载完成后展开行
        $grid.setRowExpand(row, true)
      })
    },
    confirmAdd() {
      if(this.tempObj.appLogo) this.saveRowEvent(this.tempObj);
      this.dialogVisible = false;
    },
    cutObj(val) {
      this.dialogVisible = true;
      this.tempObj = val;
    },
    customUploading(file) { // 自定义上传文件
      const t = this;
      this.$base64Img(file).then(res => {		//调用全局方法
          // t.tempImg = res;
          t.tempObj.tempLogo = `<img class="icon" src="${res}" alt="">`;
          t.tempObj.appLogo = res;
      })
    },
    beforeUpload(file, fileList) {
        // var testmsg = file.name.substring(file.name.lastIndexOf('.')+1)
        if(file.name.indexOf('jpg') == -1 && file.name.indexOf('png') == -1) {
            this.$message.error('请上传指定类型文件');
            return false;
        }
    },


    getAllApp() {
      API.getAllApp().then(res => {
          if(res.status == 'pmp-100') {
              res.data.forEach(el => {
                if(el.appLogo) {
                  el.tempLogo = `<img class="icon" src="${el.appLogo}" alt="">`;
                }
              })
              this.tableData1 = res.data;
              this.tablePage2.totalResult = res.data.length;
              this.findList2()
          } else this.$message.error(res.message);
      })
    },
    removeSele() {
        const selectRecords = this.$refs.xTable.getCheckboxRecords();
        let arr = [];
        if(selectRecords.length) {
            selectRecords.forEach(element => arr.push(element.appUrlId));
            API.delApp(arr).then(res => {
                if(res.status == 'pmp-100') {
                    this.getAllApp();
                } else this.$message.error(res.message);
            })
        } else this.$message.error('请先选择数据');
    },
    async insertEvent (row) {
        const $table = this.$refs.xTable
        const record = {
            appId: '',
            appName: '',
            appUrl: '',
            busiCode: '',
            iscAppId: ''
        }
        const { row: newRow } = await $table.insertAt(record, row)
        await $table.setActiveCell(newRow, 'appId')
    },
    editRowEvent(row) {
      const $table = this.$refs.xTable;
      $table.setActiveRow(row);
    },
    saveRowEvent(row) {
      const $table = this.$refs.xTable;
      $table.clearActived().then(() => {
        // this.loading = true;
        API.setApp([{
            appId: row.appId,
            appName: row.appName,
            appUrl: row.appUrl,
            appUrlId: row.appUrlId ? row.appUrlId : '',
            busiCode: row.busiCode,
            appLogo: row.appLogo,
            iscAppId: row.iscAppId
        }]).then(res => {
            if(res.status == 'pmp-100') {
                this.getAllApp();
            } else this.$message.error(res.message);
        })
        // setTimeout(() => {
        //   this.loading = false;
        //   this.$XModal.message({ content: "保存成功！", status: "success" });
        // }, 300);
      });
    },
    cancelRowEvent(row) {
      const $table = this.$refs.xTable;
      this.$refs.xTable.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row);
      });
    },
    handlePageChange2 ({ currentPage, pageSize }) {
        this.tablePage2.currentPage = currentPage;
        this.tablePage2.pageSize = pageSize;
        this.findList2()
    },
    findList2 () {
        this.loading = true
        setTimeout(() => {
            this.loading = false
            this.tableData = this.tableData1.slice((this.tablePage2.currentPage - 1) * this.tablePage2.pageSize, this.tablePage2.currentPage * this.tablePage2.pageSize)
        }, 300)
    },
  }
};
</script>

<style scope>
.elDialog .el-dialog__header{
    background: white;
}
.el-popover {
  width: 203px !important;
  padding: 15px 15px 21px 20px !important;
}
.el-popover__title {
  margin: 5px 12px 20px 0 !important;
}
.el-dialog__footer {
  padding: 8px 20px 20px !important;
  text-align: right !important;
}
.iconSty {
  max-width: 45px;
  max-height: 45px;
  margin: 20px 0 0 15px;
}
.icon {
  max-width: 45px;
  max-height: 45px;
}
.knowledge {
  padding: 20px 0 0 20px;
}
.knowledge_box {
  width: 100%;
  background:#f0f2f5;
}
.knowledge_head {
  /* width: 100%; */
  height: 55px;
  padding: 17px 0 0 0;
  background:white;
  margin: 0 0 20px 0;
}
.knowledge_head_box {
  float: right;
  margin-right: 20px;
}
.knowledge_body {
  /* width: 100%; */
  /* height: 100px; */
  padding: 20px;
  background: white;
}
</style>
