window.public = {
    // 操作类型
    bilderTypeArr: [{ id: 0, name: '表情包' }, { id: 1, name: '头像' }, { id: 2, name: '壁纸' }, { id: 3, name: '制作' },],
    // 请求地址
    baseUrl: 'https://wztop.store/mini/',
    // 设置登录超时时间，单位/小时
    loginTimeoutTime: 3,
    // 关键词临时数据结构保存，避免异常误删数据库
    tableData: [
        {
          type: 0,
          explain: '表情包',
          str: '静图，金馆长，搞笑，恶搞，沙雕，动漫，小狗，猫咪'
        },
        {
          type: 1,
          explain: '头像',
          str: '情侣，恶搞，沙雕，动漫，风景'
        },
        {
          type: 2,
          explain: '壁纸',
          str: '唯美，人物，动漫，'
        },
        {
          type: 3,
          explain: '下拉刷新随机关键词',
          str: '唯美，人物，动漫'
        },
        {
          type: 4,
          explain: '身体制作tabs',
          str: '熊猫，蘑菇头，真人'
        },
        {
          type: 5,
          explain: '表情制作tabs',
          str: '动漫，暴漫'
        },
    ],
}
