<template>
  <div class="home">
    <el-container>
      <el-aside width="100px">
        <ul class="home_list">
          <template v-for="(item, index) in arr">
            <!-- <router-link :to="`/${item.pictureName == 'apply' ? 'apply' : 'knowledge'}`"> -->
              <li :key="index" class="home_list_li" :class="index == idx ? 'home_list_li_selected' : ''" @click="cutType(item, index)">
                <img :src="index == idx ? item.imgSelected : item.imgUnselected">
                <p :style="{color: index == idx ? 'white' : '#888C94'}">{{item.resourceName}}</p>
              </li>
            <!-- </router-link> -->
          </template>
        </ul>
      </el-aside>

      <el-main>
          <RoleController v-if="pageNum == 100"></RoleController>
          <notice v-if="pageNum == 200"></notice>
          <recordStatistics v-if="pageNum == 300"></recordStatistics>
          <errorLog v-if="pageNum == 400"></errorLog>
          <!-- <pictureUp v-if="pageNum == 100"></pictureUp> -->
          <!-- <knowledge v-if="pageNum == 0"></knowledge> -->
          <!-- 应用集成页面 -->
          <!-- <hotWord v-if="pageNum == 200"></hotWord> -->
          <!-- 交流园地页面 -->
          <!-- <exchange v-if="pageNum == 2"></exchange> -->
          <!-- 权限列表页面 -->
          <!-- <jurisdiction v-if="pageNum == 3"></jurisdiction> -->
          <!-- 资源共享页面 -->
          <!-- <resource v-if="pageNum == 4"></resource> -->
      </el-main>

    </el-container>
  </div>
</template>

<script>
// import API from '../service/api'
// import pictureUp from '../components/pictureUp'
// import hotWord from '../components/hotWord'
import RoleController from '../components/RoleController'
import notice from '../components/notice'
import recordStatistics from '../components/recordStatistics'
import errorLog from '../components/errorLog'
export default {
  components: {
    // pictureUp,
    // hotWord,
    RoleController,
    notice,
    recordStatistics,
    errorLog,
  },
  data () {
    return {
      idx: 0,
      arr: [
        {
          imgSelected: require(`../images/roleController.svg`), // 已选中的
          imgUnselected: require(`../images/roleController_not.svg`), // 未选中的
          resourceName: '用户管理', // 标题
          pageNum: 100, // 指定展示对应页面
        },
        {
          imgSelected: require(`../images/notice.svg`), // 已选中的
          imgUnselected: require(`../images/notice_not.svg`), // 未选中的
          resourceName: '公告管理', // 标题
          pageNum: 200, // 指定展示对应页面
        },
        {
          imgSelected: require(`../images/statistics.svg`), // 已选中的
          imgUnselected: require(`../images/statistics_not.svg`), // 未选中的
          resourceName: '充值统计', // 标题
          pageNum: 300, // 指定展示对应页面
        },
        {
          imgSelected: require(`../images/errorLog.svg`), // 已选中的
          imgUnselected: require(`../images/errorLog_not.svg`), // 未选中的
          resourceName: '报错日志统计', // 标题
          pageNum: 400, // 指定展示对应页面
        },
      ],
      pageNum: 100, // 页码
    }
  },
  created () {
    return;
    API.resourceUserId().then(res => {
      // 菜单列表不存在数据，则提示联系管理员
      if (!res.data.length) {
        this.emptyFlag = true
        return
      }
      this.arr = res.data
      // requre引入图片转换为 base64 类型，便于使用
      this.arr.forEach(element => {
        var img = element.pictureName
        element.imgSelected = require(`../images/${img}.png`)
        element.imgUnselected = require(`../images/${img}_not.png`)
        element.value = (img == 'knowledge' ? '1' : img == 'policy' ? '4' : img == 'notice' ? '6' : '7')
      })
      // 区分显示对应子组件
      this.flag = this.arr[0].pictureName == 'apply' ? 1 : this.arr[0].pictureName == 'exchangeField' ? 2 : this.arr[0].pictureName == 'jurisdiction' ? 3 : this.arr[0].pictureName == 'resource' ? 4 : 0
      // 权限列表传至 vuex, 新增编辑页面资源类型下拉可进行动态同步选择
      this.$store.commit('storage', this.arr)
      // 切换不同菜单，展示对应列表数据。默认数据第一条
      this.$store.commit('cutType', this.arr[0].pictureName)
    })
  },
  methods: {
    cutType (item, index) {
      this.idx = index
      this.pageNum = item.pageNum
    }
  }
}
</script>

<style>
  /* body > .el-container {
    margin-bottom: 40px;
  } */
    .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    /* line-height: 200px; */
    /* height: 100%; */
  }
   .el-main {
    background-color: #f0f2f5;
    color: #333;
    text-align: left;
    /* line-height: 0; */

  }
  .home .el-main {
    padding: 0;
    height: calc(100vh - 60px); ;

  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .home .el-aside {
    background: #E8EDF0;
    line-height: 0;
  }
  .home_list {
    width: 90px;
    margin: 0 auto;
  }
  .home_list_li {
    width: 100%;
    height: 80px;
    margin: 5px 0;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .home_list_li img{
    margin-top: 18px;
  }
  .home_list_li p{
    margin-top: 18px;
    color: #888C94;
  }
  .home_list_li_selected {
    background: #00706B;
    cursor: default;
    color: white;
  }
  a {
    text-decoration: none !important;
  }
  .router-link-active {
    text-decoration: none !important;
  }
</style>
