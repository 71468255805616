<template>
  <div class="knowledge">

    <div class="knowledge_box" v-show="flag">
      <div class="knowledge_head">
          <el-form ref="form" class="el_form" :model="form" label-width="85px">
            <!-- <el-form-item label="资源类型：">
                <el-select v-model="form.type" placeholder="请选择" @change="getData" size="small">
                    <el-option label="中台要闻" value="1"></el-option>
                    <el-option label="通知公告" value="6"></el-option>
                    <el-option label="政策法规" value="2"></el-option>
                    <el-option label="管理办法" value="3"></el-option>
                    <el-option label="政策动态" value="4"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="标题：">
                <el-input v-model="form.name" size="small"></el-input>
            </el-form-item>
            <div class="deputyControl">
                <el-button class="inquire" size="medium" @click="getData">查询</el-button>
                <el-button class="inquire" size="medium" @click="add">新增</el-button>
                <el-button class="inquire inquire_fb" size="medium" @click="remove">删除</el-button>
            </div>
          </el-form>
      </div>
      <div class="knowledge_body">
        <div class="knowledge_body_box">
          <el-table
                ref="multipleTable"
                :data="tableData"
                @selection-change="handleSelectionChange"
                tooltip-effect="dark"
                :header-cell-style="{textAlign:'center',background:'#eef1f6'}"
                :cell-style="{textAlign:'center'}"
                style="width: 100%">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        type="index"
                        label="序号"
                        width="60">
                    </el-table-column>
                    <el-table-column
                        prop="titleType"
                        width="220"
                        label="资源类型"
                        show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{
                                    scope.row.titleType == 1 ? '中台要闻' :
                                    scope.row.titleType == 4 ? '政策动态' :
                                    scope.row.titleType == 6 ? '通知公告' : '基层动态'
                                }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="titleName"
                        label="标题"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="receiveTime"
                        label="时间"
                        show-overflow-tooltip
                        width="200">
                    </el-table-column>
                    <el-table-column
                        width="150"
                        label="操作">
                        <template scope="scope">
                            <el-button class="compile" size="medium" @click="compile(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <vxe-pager
                border
                size="medium"
                :current-page="tablePage.currentPage"
                :page-size="tablePage.pageSize"
                :page-sizes="tablePage.pageSizes"
                :total="tablePage.totalResult"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
            </vxe-pager>
        </div>
      </div>
    </div>

    <div class="knowledge_box" v-show="!flag">
      <div class="knowledge_head">
          <el-form ref="form" class="el_form" :model="form" label-width="85px">
            <el-form-item label="资源类型：" style="margin-left: 20px;">
                <el-select v-model="unify.value" :disabled="configurationPageSelectShow" placeholder="请选择" size="small">
                    <!-- <el-option label="中台要闻" value="1"></el-option>
                    <el-option label="通知公告" value="6"></el-option>
                    <el-option label="政策动态" value="4"></el-option>
                    <el-option label="基层动态" value="7"></el-option> -->
                    <el-option v-for="(p,q) in knowledgeArr" :label="p.resourceName" :value="p.value" :key="q"></el-option>
                </el-select>
            </el-form-item>
            <div class="deputyControl">
                <el-button class="inquire" size="medium" @click="save">保存</el-button>
                <el-button class="inquire" size="medium" @click="cancelEvent">取消</el-button>
            </div>
          </el-form>
      </div>
      <div class="knowledge_body">
        <div class="knowledge_body_box" v-loading.fullscreen.lock="fullscreenLoading">

          <!-- 中台要闻 -->
          <template v-if="unify.value == 1">
              <el-form :model="unify" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <div class="perLine">
                      <div style="width:50%;float:left;">
                          <div class="selectKind_title">标题：</div>
                          <el-form-item prop="title" class="form__item">
                              <el-input v-model="unify.title" size="small" class="el_input" placeholder="请输入内容"></el-input>
                          </el-form-item>
                      </div>
                      <div style="width:50%;float:right;">
                          <div class="selectKind_title">时间：</div>
                          <el-form-item prop="startTime" class="form__item">
                              <div class="block">
                                  <el-date-picker
                                      class="el_select__time"
                                      v-model="unify.startTime"
                                      size="small"
                                      type="date"
                                      placeholder="选择日期">
                                  </el-date-picker>
                              </div>
                          </el-form-item>
                      </div>
                  </div>
                  <div class="perLine">
                      <div class="selectKind_title" style="width: 80px;">富文本：</div>
                      <el-form-item prop="htmlContent" class="form__item">
                          <!-- <wangeditor style="line-height:40px !important" @change="wangeditorChange" :value="unify.htmlContent"></wangeditor> -->
                      </el-form-item>
                  </div>
                  <div class="selectKind_title_upload_demo">上传图片：<div v-if="unify.img" class="upload_box"></div></div>
                      <el-upload
                          class="avatar-uploader upload_demo journalism_upload"
                          action="#"
                          drag
                          :before-remove="beforeRemove"
                          :limit="1"
                          :file-list="unify.imgList"
                          :http-request="httpRequest"
                          :before-upload="beforeAvatarUpload">
                          <img v-if="unify.img" :src="unify.img" class="avatar_upload_img">
                          <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text" v-if="!unify.img">将图片拖到此处，或<em>点击上传</em></div>
                          <div class="el-upload__tip" slot="tip">只能上传jpg/png图片，限10M，建议尺寸(280px * 128px)</div>
                          <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                      </el-upload>
              </el-form>
          </template>

          <!-- 政策动态 基层动态 -->
          <template v-if="unify.value == 4 || unify.value == 7">
              <el-form :model="unify" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <div class="perLine">
                      <div style="width:50%;float:left;">
                          <div class="selectKind_title">标题：</div>
                          <el-form-item prop="title" class="form__item">
                              <el-input v-model="unify.title" size="small" class="el_input" placeholder="请输入内容"></el-input>
                          </el-form-item>
                      </div>
                      <div style="width:50%;float:right;">
                          <div class="selectKind_title">时间：</div>
                          <el-form-item prop="startTime" class="form__item">
                              <div class="block">
                                  <el-date-picker
                                      class="el_select__time"
                                      v-model="unify.startTime"
                                      size="small"
                                      type="date"
                                      placeholder="选择日期">
                                  </el-date-picker>
                              </div>
                          </el-form-item>
                      </div>
                  </div>
                  <div style="width:1200px;min-height: 32px;margin: 0 0 20px 80px;position: relative;">
                      <div v-if="unify.noticeFile1.length" style="width: 100px;height: 32px;position: absolute;top: 0px;left: 0px;z-index: 99;cursor: not-allowed;"></div>
                      <el-upload
                          class="upload-demo"
                          action="string"
                          :http-request="customUploading"
                          :before-upload="beforeUploadPdf"
                          :file-list="unify.noticeFile1"
                          :on-remove="fileRemove">
                              <el-button size="small">点击上传<i style="margin-top: 0;" class="el-icon-upload el-icon--right"></i></el-button>
                              <div slot="tip" class="el-upload__tip el-upload__tip_pdf">注：只能上传pdf文件</div>
                      </el-upload>
                  </div>
                  <div class="selectKind_title_upload_demo">上传图片：<div v-if="unify.img" class="upload_box_dynamic"></div></div>
                      <el-upload
                          class="avatar-uploader upload_demo journalism_upload dynamic_demo"
                          action="#"
                          drag
                          :before-remove="beforeRemove"
                          :limit="1"
                          :file-list="unify.imgList"
                          :http-request="httpRequest"
                          :before-upload="beforeAvatarUpload">
                          <img v-if="unify.img" :src="unify.img" class="avatar_upload_img">
                          <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                          <i class="el-icon-upload" style="margin-top: 24px;"></i>
                          <!-- <div class="el-upload__text" v-if="!unify.img">将图片拖到此处，或<em>点击上传</em></div> -->
                          <div class="el-upload__tip" slot="tip">只能上传jpg/png图片，限10M，建议尺寸(120px * 92px)</div>
                          <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                      </el-upload>
              </el-form>
          </template>

          <!-- 通知公告 -->
          <template v-if="unify.value == 6">
              <el-form :model="unify" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <div class="perLine">
                      <div style="width:50%;float:left;">
                          <div class="selectKind_title">标题：</div>
                          <el-form-item prop="title" class="form__item">
                              <el-input v-model="unify.title" size="small" class="el_input" placeholder="请输入内容"></el-input>
                          </el-form-item>
                      </div>
                      <div style="width:50%;float:right;">
                          <div class="selectKind_title">专业系统：</div>
                          <el-form-item prop="system" class="form__item">
                              <el-select v-model="unify.system" placeholder="请选择" class="el_select__small" size="small">
                                  <el-option v-for="(item,index) in unify.systemArr" :label="item.appName" :key="index" :value="item.appUrlId"></el-option>
                              </el-select>
                          </el-form-item>
                      </div>
                  </div>
                  <div class="perLine">
                      <div style="width:50%;float:left;">
                          <div class="selectKind_title">开始时间：</div>
                          <el-form-item prop="startTime" class="form__item">
                              <el-date-picker
                                  class="el_select__time"
                                  v-model="unify.startTime"
                                  :picker-options="pickerOptions1"
                                  size="small"
                                  type="date"
                                  placeholder="选择日期">
                              </el-date-picker>
                          </el-form-item>
                      </div>
                      <div style="width:50%;float:right;">
                          <div class="selectKind_title">截止时间：</div>
                          <el-form-item prop="startTime" class="form__item">
                              <div class="block">
                                  <el-date-picker
                                      class="el_select__time"
                                      v-model="unify.endTime"
                                      :picker-options="pickerOptions"
                                      :disabled="!unify.startTime"
                                      start-placeholder="开始日期"
                                      size="small"
                                      type="date"
                                      placeholder="选择日期">
                                  </el-date-picker>
                              </div>
                          </el-form-item>
                      </div>
                  </div>
                  <div class="perLine">
                      <div style="width:50%;float:left;">
                          <div class="selectKind_title">链接名称：</div>
                          <el-form-item class="form__item">
                              <el-input v-model="unify.linkName" size="small" class="el_input" placeholder="请输入内容"></el-input>
                          </el-form-item>
                      </div>
                      <div style="width:50%;float:right;">
                          <div class="selectKind_title">链接地址：</div>
                          <el-form-item class="form__item">
                              <el-input v-model="unify.linkSite" size="small" class="el_input" placeholder="请输入内容"></el-input>
                          </el-form-item>
                      </div>
                  </div>
                  <div class="perLine">
                      <div class="selectKind_title">正文：</div>
                      <el-form-item prop="text" class="form__item">
                          <el-input v-model="unify.text" type="textarea" size="small" class="el__input" placeholder="请输入内容"></el-input>
                      </el-form-item>
                  </div>
                  <div style="width:1200px;min-height: 32px;margin: 0 0 20px 80px;position: relative;">
                      <div v-if="unify.noticeFile.length" style="width: 100px;height: 32px;position: absolute;top: 0px;left: 0px;z-index: 99;cursor: not-allowed;"></div>
                      <el-upload
                          class="upload-demo"
                          action="string"
                          :http-request="customUploading"
                          :before-upload="beforeUploadPdf"
                          :file-list="unify.noticeFile"
                          :on-remove="fileRemove">
                              <el-button size="small">点击上传<i style="margin-top: 0;" class="el-icon-upload el-icon--right"></i></el-button>
                              <div slot="tip" class="el-upload__tip el-upload__tip_pdf">注：只能上传pdf文件</div>
                      </el-upload>
                  </div>
              </el-form>
          </template>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import API from '../service/api'
// import wangeditor from './wangeditor'
import axios from 'axios'
// var env = {
//   uploadingFileSite: 'http://192.168.1.239:17777/res/pmpFile/upload' // 上传文件地址
// }
var env = JSON.parse(localStorage.getItem('env'));
export default {
  name: 'knowledge',
  components: {
    // wangeditor
  },
  data () {
    return {
      fullscreenLoading: false, // 加载框
      configurationPageSelectShow: false, // 配置页选择器
      form: {
        name: '',
        type: '1' // 1中台要闻、4政策动态、6通知公告、 基层动态
      },
      tableData: [],
      tablePage: {
        currentPage: 1,
        pageSize: 5,
        totalResult: 0,
        pageSizes: [5, 10, 15]
      },
      flag: true,
      unify: {
        value: '', // 类型选择
        title: '', // 标题内容
        titleNo: '', // id
        system: '', // 专业系统
        systemArr: [], // 应用导航数据
        startTime: '', // 开始时间
        endTime: '', // 截止时间
        linkName: '', // 链接名称
        linkSite: '', // 链接地址
        text: '', // 正文
        noticeFile: [], // 通知公告文件数组
        noticeFile1: [], // 通知公告文件数组
        htmlContent: '', // 富文本字符串
        img: '', // 图片base64
        imgList: [], // 图片展示列表
        imgName: '' // 图片名称
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        startTime: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        text: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        system: [{ required: true, message: '请选择系统', trigger: 'change' }],
        systhtmlContentem: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      pickerOptions: {},
      pickerOptions1: {},
      pitchOnArr: [],
      knowledgeArr: []
    }
  },
  watch: {
    '$store.state.index': {
      // 类型切换后重置表单状态
      handler (newVal) {
        this.form.name = ''
        this.form.type = newVal
        this.getData()
        this.flag = true // 展示列表页
        this.tableData = [] // 清空列表数据
      },
      immediate: true
    },
    '$store.state.arr': {
      // 筛除交流园地、应用集成等数据
      handler (newVal) {
        const data = newVal.slice()
        data.forEach((element, i) => {
          if (element.pictureName == 'exchangeField' || element.pictureName == 'apply') {
            data.splice(i, 1)
          }
        })
        this.knowledgeArr = data
      },
      immediate: true,
      deep: true
    },
    'unify.startTime': {
      // 类型切换后重置表单状态
      handler (newVal) {
        let time
        if (this.disabledFlag) time = new Date(new Date(newVal).getTime())
        else time = new Date(new Date(newVal).getTime() + 86400000)
        this.pickerOptions = {
          disabledDate: (v) => {
            return v.getTime() < time
          }
        }
      },
      deep: true
    },
    'unify.endTime': {
      // 类型切换后重置表单状态
      handler (newVal) {
        const time = new Date(new Date(newVal).getTime() - 86400000)
        this.pickerOptions1 = {
          disabledDate: (v) => {
            return v.getTime() > time
          }
        }
      },
      deep: true
    }
  },
  created () {
    this.getData()
    this.getAllApp()
  },
  methods: {
    getData () {
      // 状态为空退出
      if (!this.form.type) return
      // this.form.type = this.$store.state.index;
      this.fullscreenLoading = true
      let obj = {}
      if (this.form.type == 7) obj = { type: Number(this.form.type), ispub: '0', titleName: this.form.name, pageIndex: this.tablePage.currentPage, pageSize: this.tablePage.pageSize }
      else obj = { type: Number(this.form.type), name: this.form.name, ispub: '0', pageNo: this.tablePage.currentPage, pageSize: this.tablePage.pageSize }
      API.titleList2(obj, (this.form.type == '7' ? 'titleDept/list' : 'title/list')).then(res => {
        if (res.status == 'pmp-100') {
          if (this.form.type == '7') {
            // 基层动态
            this.tableData = res.data.list
            this.tablePage.totalResult = res.data.total
          } else {
            // 中台要闻，政策动态，通知公告
            this.tableData = res.data.data
            this.tablePage.totalResult = res.data.count
          }
          this.fullscreenLoading = false
        } else {
          this.fullscreenLoading = false
          this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
        this.fullscreenLoading = false
      })
    },
    getAllApp () {
      API.getByUser().then(res => { // 获取应用导航数据
        if (res.status == 'pmp-100') {
          this.unify.systemArr = res.data
        } else this.$message.error(res.message)
      })
    },
    add () {
      this.emptyFormData()
      this.configurationPageSelectShow = false // 新增下拉选择器可使用
      this.flag = false // 展示新增修改页
      this.unify.value = this.form.type
    },
    remove () {
      if (this.pitchOnArr.length != 0) {
        const arr = []
        this.pitchOnArr.forEach(el => { arr.push(el.titleNo) })
        API[this.form.type == 7 ? 'titleDeptRemove' : 'pitchOnRemove'](arr).then(res => {
          if (res.status == 'pmp-100') {
            this.getData()
            this.$message.success('删除成功')
          } else this.$message.error(res.message)
        })
      } else this.$message.warning('请选择数据')
    },
    fileList (pictureList, name) { // 上传文件名字不显示
      console.log(pictureList)
      this.unify[name] = pictureList.map(item => {
        return {
          name: item.fileName,
          url: '',
          fileId: item.fileId,
          fileName: item.fileName
        }
      })
      this.disabledUploadBtn = true // 管理办法证词法规 已有文件数据 允许上传
    },
    emptyFormData () { // 清空上次表单留存的值
      this.$nextTick(() => {
        if (this.$refs.ruleForm != undefined) this.$refs.ruleForm.resetFields()
      })
      Object.keys(this.unify).forEach(key => {
        if (key != 'value' && key != 'noticeFile' && key != 'noticeFile1' && key != 'startTime' && key != 'imgList' && key != 'systemArr') {
          this.unify[key] = '' // 清空上次留存的值
        } else if (key == 'startTime') {
          this.unify.startTime = ''
        } else {
          this.unify.imgList = []
          this.unify.noticeFile = []
          this.unify.noticeFile1 = []
        }
      })
      this.disabledUploadBtn = false // 允许文件上传
      // if(newVal == '6') this.getAllApp();
    },
    compile (v) {
      this.fullscreenLoading = true // 加载等待对话
      this.configurationPageSelectShow = true // 编辑中下拉选择器禁用
      // Object.keys(this.unify).forEach(key => (this.unify[key] = '')); // 清空上次留存的值
      this.emptyFormData()
      this.unify.noticeFile1 = []
      this.unify.noticeFile = []
      this.unify.value = String(v.titleType)
      if (v.titleType != 6) { // 区分不同请求接口 6为通知公告
        API[this.form.type == 7 ? 'titleDeptDetail' : 'titleDetail']({ titleNo: v.titleNo }).then(res => { // 获取 1 中台要闻、2 政策法规、3 管理办法详情
          if (res.status == 'pmp-100') {
            this.unify.title = res.data.titleName
            this.unify.titleNo = res.data.titleNo
            this.unify.startTime = res.data.receiveTime
            this.unify.img = res.data.titlePicture
            this.unify.text = res.data.viewCount
            this.unify.htmlContent = res.data.htmlContent
            if (res.data.noticeFile != null && res.data.noticeFile) this.fileList([res.data.noticeFile], 'noticeFile1')
            else this.disabledUploadBtn = false // 管理办法证词法规 没有文件数据 禁止上传
            // 政策动态和中台要闻进入循环图片文件列表
            if (this.unify.value == 4 || this.unify.value == 1 || this.unify.value == 7) {
              // 判断是否存在img
              if (res.data.titlePicture) {
                const arr = [{ name: res.data.titlePictureName }]
                this.unify.imgList = arr.map(item => {
                  return {
                    name: item.name,
                    url: ''
                  }
                })
              }
            }
          } else this.$message.error(res.message)
          this.fullscreenLoading = false // 取消加载动画
        })
      } else {
        API.detailNotice({ id: v.titleNo }).then(res => { // 获取通知公告详情
          if (res.status == 'pmp-100') {
            if (!res.data) return
            this.unify.title = res.data.noticeTitle
            this.unify.startTime = res.data.publishDate
            this.unify.titleNo = res.data.noticeId
            this.unify.linkName = res.data.urlName
            this.unify.linkSite = res.data.noticeUrl
            this.unify.endTime = res.data.effectDate
            this.unify.text = res.data.noticeContent
            this.unify.system = res.data.appId
            this.fileList(res.data.noticeFiles, 'noticeFile')
          } else this.$message.error(res.message)
          this.fullscreenLoading = false // 取消加载动画
        })
      }
      this.$emit('skipCompile', 2) // 展示配置页
      this.flag = false
      this.disabledFlag = true
    },
    switchTimeFormat (time, name, v, r) {
      if (!time) return
      const dateTime = new Date(time)
      const year = dateTime.getFullYear()
      const month = dateTime.getMonth() + 1
      const date = dateTime.getDate()
      // const hour = dateTime.getHours();
      // const minute = dateTime.getMinutes();
      // const second = dateTime.getSeconds();
      if (v == 6) this.unify[name] = `${year}-${this.joint(month)}-${this.joint(date)}`
      else this.unify[name] = `${year}-${this.joint(month)}-${this.joint(date)} 00:00:00`
    },
    joint (v) {
      return v < 10 ? '0' + v : v
    },
    save () {
      const t = this
      if (t.unify.value == '') t.$message.error('请选择资源类型')
      else {
        t.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 时间转化格式
            t.switchTimeFormat(t.unify.startTime, 'startTime', t.unify.value)
            t.switchTimeFormat(t.unify.endTime, 'endTime', t.unify.value)
            if (t.unify.value == 1 || t.unify.value == 2 || t.unify.value == 3 || t.unify.value == 4 || t.unify.value == 7) {
              let obj
              if (t.unify.value == 2 || t.unify.value == 3) { // 区分不同接口请求发送的参数  政策法规和管理办法
                if (t.unify.noticeFile1.length == 0) return t.$message.error('请至少上传一个文件') // noticeFile1 如果存在代表已有文件上传
                obj = {
                  receiveTime: t.unify.startTime,
                  noticeFile: t.unify.noticeFile1.length ? t.unify.noticeFile1[0] : {},
                  titleNo: t.unify.titleNo,
                  titleName: t.unify.title,
                  titleType: t.unify.value
                }
              } else if (t.unify.value == 4 || t.unify.value == 7) { // 政策动态及基层动态配置参数
                if (t.unify.img == '') return t.$message.error('请上传图片')
                if (t.unify.noticeFile1.length == 0) return t.$message.error('请至少上传一个文件') // noticeFile1 如果存在代表已有文件上传
                obj = {
                  receiveTime: t.unify.startTime,
                  noticeFile: t.unify.noticeFile1.length ? t.unify.noticeFile1[0] : {},
                  titleNo: t.unify.titleNo,
                  titleName: t.unify.title,
                  titleType: t.unify.value,
                  titlePicture: t.unify.img,
                  titlePictureName: t.unify.imgName
                }
              } else { // 大事记组字段判断
                if (!t.unify.htmlContent) {
                  t.$message.error('请填写富文本内容')
                  return
                }
                if (t.unify.img == '') return t.$message.error('请上传图片')
                obj = {
                  receiveTime: t.unify.startTime,
                  titleNo: t.unify.titleNo,
                  htmlContent: t.unify.htmlContent,
                  titleName: t.unify.title,
                  titleType: t.unify.value,
                  titlePicture: t.unify.img,
                  titlePictureName: t.unify.imgName
                }
              }
              // 上传过慢，设置加载框
              t.fullscreenLoading = true
              API[t.unify.value == 7 ? 'titleDeptSave' : 'titleSave'](obj).then(res => {
                t.fullscreenLoading = false
                if (res.status == 'pmp-100') {
                  t.$message.success('保存成功')
                  t.getData()
                  t.$emit('skipCompile', 0) // 展示配置页
                  t.flag = true
                } else t.$message.error(res.message)
              })
            } else if (t.unify.value == 6) {
              const systemAppId = t.unify.systemArr.find(el => el.appUrlId == t.unify.system) // 通过 唯一的appUrlId找到 appId
              API.saveNotice({
                appId: systemAppId.appUrlId,
                effectDate: t.unify.endTime,
                noticeContent: t.unify.text,
                noticeFiles: t.unify.noticeFile ? t.unify.noticeFile : [],
                noticeId: t.unify.titleNo,
                noticeTitle: t.unify.title,
                noticeUrl: t.unify.linkSite,
                urlName: t.unify.linkName,
                publishDate: t.unify.startTime
              }).then(res => {
                if (res.status == 'pmp-100') {
                  t.$message.success('保存成功')
                  t.getData()
                  t.$emit('skipCompile', 0) // 展示配置页
                  t.flag = true
                } else t.$message.error(res.message)
              })
            }
          } else return false
        })
      }
    },
    cancelEvent () {
      this.flag = true
      this.configurationPageSelectShow = false // 非编辑中下拉选择器可使用
    },
    handleSelectionChange (v) {
      this.pitchOnArr = v
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.getData()
    },
    beforeUploadPdf (file) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user == null || user == undefined || user == '') {
        window.alert('缺少用户信息，请重新登录！')
        return false
      }
      const isJPG = (file.type === 'application/pdf')
      if (!isJPG) {
        this.$message.error('上传文件只能是 pdf!')
      }
      return isJPG
    },
    // 图片上传之前的格式设置
    beforeAvatarUpload (file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png')
      // const isLt2M = file.size / 1024 / 1024 < 10
      const isLt2M = file.size / 1024 < 10240
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10M!')
      }
      return isJPG && isLt2M
    },
    httpRequest (data) {
      const _this = this
      const rd = new FileReader() // 创建文件读取对象
      const file = data.file
      rd.readAsDataURL(file) // 文件读取装换为base64类型
      _this.unify.imgName = file.name // 存储图片名称
      rd.onloadend = function (e) {
        _this.unify.img = this.result // this指向当前方法onloadend的作用域
      }
    },
    beforeRemove (file) {
      this.unify.img = ''
    },
    wangeditorChange (val) {
      this.unify.htmlContent = val
    },
    fileRemove (file, fileList) {
      if (this.unify.value == 6) {
        this.unify.noticeFile.forEach((ele, j) => {
          if (ele.uid == file.uid) {
            this.unify.noticeFile.splice(j, 1)
          }
        })
      } else {
        this.unify.noticeFile1 = []
        this.disabledUploadBtn = false // 管理办法证词法规 已删除 禁止上传
      }
    },
    customUploading (file) { // 自定义上传文件
    console.log(file)
      const user = JSON.parse(localStorage.getItem('user'))
      var param = new FormData()
      param.append('files', file.file)
      param.append('userName', user.userName)
      console.log(param)
      return;
      this.fullscreenLoading = true
      axios({
        url: `${env.uploadingFileSite}`,
        method: 'POST',
        data: param,
        headers: {
          'Content-Type': 'multipart/form-data;charset=utf-8'
        }
      }).then(res => {
        if (res.data.status == 100) {
          this.$message({
            message: '上传成功',
            type: 'success'
          })
          if (this.unify.value == 6) {
            if (this.unify.noticeFile == '') this.unify.noticeFile = []
            const arr = [res.data].map(item => {
              return {
                name: file.file.name,
                url: '',
                fileId: item.data[0],
                fileName: file.file.name,
                uid: file.file.uid
              }
            })
            this.unify.noticeFile = this.unify.noticeFile.concat(arr)
          } else {
            this.fileList([{ fileId: res.data.data[0], fileName: file.file.name, name: file.file.name, url: '' }], 'noticeFile1')
          }
        } else this.$message.error(res.data.message)
        this.fullscreenLoading = false
      }).catch(err => {
        this.fullscreenLoading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main {
  line-height: 0;
}
.knowledge {
  padding: 20px 0 0 20px;
}
.knowledge_box {
  width: 100%;
  background:#f0f2f5;
}
.knowledge_head {
  /* width: 100%; */
  height: 55px;
  padding: 17px 0 0 0;
  background:white;
  margin: 0 0 20px 0;
}
.el_form {
	display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.inquire {
  height: 34px;
  background:#00706b;
  color:white;
}
.inquire:hover {
  height: 34px;
  background:#00706b;
  color:white;
}
.inquire_fb {
  background:white;
  color: black;
  border: 1px solid #DCDFE6;
}
.inquire_fb:hover {
  background:white;
  color: black;
  border: 1px solid #DCDFE6;
}
.el-form-item {
    margin-bottom: 15px;
}
.deputyControl {
    float: right;
    margin-right: 20px;
}

.knowledge_body {
  /* width: 100%; */
  /* height: 100px; */
  padding: 20px;
  background: white;
}
.knowledge_body_box {
  width: 100%;
  height: 100%;
  /* background: url(../images/overall.png) no-repeat;
  background-size: 100% 100%; */
}
.knowledge_body_box >>> .vxe-pager.size--medium {
    margin-top: 15px;
}

.el-select-dropdown__item.selected {
    color: #00706b !important;
}
.el-input--small .el-input__icon {
    height: auto !important;
    /* // line-height: 39px !important; */
}
.el-checkbox__inner {
    background-color:white !important;
}

.compile:hover {
    background:white !important;
    border:1px solid #DCDFE6 !important;
    color:black !important;
}

.selectKind {
    width:100%;
    font-size: 14px;
    margin: 0 0 15px 0;
}
.selectKind_title {
    width:70px;
    float:left;
    height:32px;
    line-height:32px;
    text-align: center;
}
.perLine {
    overflow: hidden;
    font-size: 14px;
    margin: 5px 0;

}
.el__input {
  width:90% !important;
}
.el__input textarea {
    min-height:120px !important;
    max-height:200px !important;
}
.el_input {
    width:80% !important;
}
.el_select__small {
    width:80%;
}
.el_select__time {
    width: 80% !important;
}

.form__item {
    margin-bottom: 15px;
    /* // line-height: 0 !important; */
}
.form__item >>> .el-form-item__content {
    margin-left:0 !important;
    line-height: 0 !important;
}
.form__item >>> .el-form-item__content .el-input--small {
    margin-left: 11px !important;
}
.text_wz {
    min-height: 300px;
}
.upload-demo >>> .el-upload-list--text {
    margin-top: 20px !important;
}

.form__item >>> .el-form-item__content .el-form-item__error {
    left:96px !important;
    padding-top: 3px !important;
}
.upload_demo {
    margin-top: 20px;
    /* // .el-upload__tip {
    //     padding-left: 70px;
    // } */
}
/*  设置上传图片尺寸大小 */
.upload_demo >>> .el-upload-dragger {
    width: 282px !important;
    height: 130px !important;
}
.journalism_upload >>> .el-icon-upload {
    /* margin-top: 50px; */
    color: #00706A;
    line-height: 40px;
}
.dynamic_demo >>> .el-upload-dragger {
    width: 120px !important;
    height: 92px !important;
}

.upload_demo >>> .el-upload-dragger img {
    width: 100%!important;
    height: 100%!important;
}
.perLine >>> .el-upload .upload_demo {
  margin-left: 11px;
}
.selectKind_title_upload_demo {
    width:79px;
    float:left;
    height:32px;
    line-height:32px;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    position: relative;
}
.upload_box {
    width: 300px;
    height: 140px;
    position: absolute;
    top: -10px;
    left: 70px;
    z-index: 99;
    cursor: not-allowed;
}
.upload_box_dynamic {
    width: 126px;
    height: 100px;
    position: absolute;
    top: -26px;
    left: 77px;
    z-index: 99;
    cursor: not-allowed;
}
.avatar-uploader {
    margin-top: 20px;
}
.avatar-uploader >>> .el-upload-list__item {
    margin: 10px 0 0 80px;
    width: 285px;
}
.el-upload__tip {
    padding: 10px 0 10px 80px;
}
.el-upload__tip_pdf {
    padding: 0 !important;
    margin: -16px 0 0 115px !important;
}
.el-upload-list__item-name {
    padding-left: 70px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar_upload_img {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
