import axios from 'axios'

// var env = {baseUrl: 'http://43.138.15.214:88/'}
var env = { baseUrl: 'https://admin.gpt.hzpray.com/admin/' }
axios.defaults.withCredentials = false
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8' // 配置请求头
let token = ''

// 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
  if (config.url.indexOf('sys/user/login') == -1) {
    config.headers.common.Authorization = JSON.parse(localStorage.getItem('_t_o_k_e_n_')).token
    // config.headers.common.account = JSON.parse(localStorage.getItem('_t_o_k_e_n_')).account
  }
  // config.headers.common.token = token
  return config
}, function (error) {
  console.info(error)
  return Promise.reject(error)
})

// 添加一个响应拦截器
axios.interceptors.response.use(function (response) {
  // console.log(response)
  if (response.data && response.data.status) {
    if (response.data.status === '999997') {
      return response
    }
  }
  return response
}, function (error) {
  // Do something with response error
  // console.dir(error);
  // this.$message.error('服务连接错误');
  // alert('服务连接错误')
  return Promise.reject(error)
})

// 通用方法
export const GET = (url, params) => {
  return axios.get(`${env.baseUrl}${url}`, { params: params }).then(res => res.data)
}

export const POST = (url, params) => {
  return axios.post(`${env.baseUrl}${url}`, params).then(res => res.data)
}
