import * as API from './request'

export default {

  // 登录
  login: (data) => {
    return API.POST('sys/user/login', data)
  },

  // 重置密码
  resetPass: (data) => {
    return API.POST('account/reset/password', data)
  },

  // 获取账户列表
  getAccountPage: (data) => {
    return API.POST('account/page', data)
  },

  // 公告列表
  getNoticePage: (data) => {
    return API.POST('notice/page', data)
  },

  // 新增公告
  insertNotice: (data) => {
    return API.POST('notice/insert', data)
  },
  // 修改公告
  updateNotice: (data) => {
    return API.POST('notice/update', data)
  },

  // 删除公告
  removeNotice: (data) => {
    return API.POST('notice/delete', data)
  },

  // 角色列表
  getRoleList: (data) => {
    return API.GET('role/list')
  },

  // // 获取账户列表
  // getAccountPage: (data) => {
  //   return API.POST('account/page', data)
  // },

  // 充值
  setRechargeRecordInsert: (data) => {
    return API.POST('recharge/record/insert', data)
  },

  // 充值挡位列表
  getRechargeLevelList: () => {
    return API.GET('recharge/level/list')
  },

  // 充值记录分页列表
  rechargeRecordPage: (data) => {
    return API.POST('recharge/record/page', data)
  },

  // 充值记录统计
  rechargeRecordStatistic: (data) => {
    return API.POST('recharge/record/statistic', data)
  },

  // 报错日志统计
  exceptionLogPage: (data) => {
    return API.POST('exception/log/page', data)
  },






  

  // 根据id查询数据组
  queryOne: (data) => {
    return API.GET(`queryOne?id=${data}`)
  },

  // 根据条件查询数据组
  queryList: (data) => {
    return API.GET(`queryList?page=${data.currentPage}&pageSize=${data.pageSize}&type=${data.type}&name=${data.name}`)
  },

  // 上传图片信息
  save: (data) => {
    return API.POST('save', JSON.stringify(data))
  },

  // 更新已上传图片信息
  update: (data) => {
    return API.POST('uploadFile', data)
  },

  // 搜索热词查询
  hotWordQuery: () => {
    return API.GET('hotWordQuery')
  },

  // 搜索热词内容更新
  hotWordSave: (data) => {
    return API.POST('hotWordSave', data)
  },
}
