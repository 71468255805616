import Vue from 'vue'
import VueRouter from 'vue-router'
import knowledge from '../components/knowledge.vue'
import apply from '../components/apply.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/knowledge'
  // },
  {
    path: '/knowledge',
    name: 'Knowledge',
    component: knowledge
  },
  // {
  //   path: '/policy',
  //   name: 'Policy',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/policy.vue')
  // },
  // {
  //   path: '/notice',
  //   name: 'Notice',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/notice.vue')
  // },
  // {
  //   path: '/substratum',
  //   name: 'Substratum',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/substratum.vue')
  // },
  {
    path: '/apply',
    name: 'Apply',
    component: apply
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
