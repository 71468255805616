<template>
  <!-- 公告管理 -->
    <div class="knowledge">
      <div class="knowledge_box">
          <div class="knowledge_head">
              <div class="knowledge_head_box">
                <vxe-button icon="fa fa-plus" style="margin: 0 0 18px 0;background:#00706b;color:white;" @click="insertEvent()">新增</vxe-button>
              </div>
          </div>
          <div class="knowledge_body">
              <vxe-table
                  resizable
                  show-overflow
                  keep-source
                  align="center"
                  ref="xTable"
                  :loading="loading"
                  :data="tableData"
                  :edit-config="{trigger: 'manual', mode: 'row', autoClear: false, showStatus: true}"
              >
                  <vxe-table-column field="id" width="200" title="id"></vxe-table-column>
                  <vxe-table-column
                      field="sortNo"
                      title="排序"
                      width="80"
                      :edit-render="{}"
                  >
                    <template #edit="{ row }">
                      <vxe-input v-model="row.sortNo" type="text" placeholder="请输入序号"></vxe-input>
                    </template>
                  </vxe-table-column>
                  <vxe-table-column
                      field="content"
                      title="公告内容"
                      :edit-render="{}"
                  >
                    <template #edit="{ row }">
                      <vxe-input v-model="row.content" type="text" placeholder="请输入昵称"></vxe-input>
                    </template>
                  </vxe-table-column>
                  <vxe-table-column
                      field="createTime"
                      width="200"
                      title="创建时间"
                  ></vxe-table-column>
                  <vxe-table-column
                      field="releaseStatus"
                      title="上下架状态"
                      width="100"
                  >
                    <template #default="{ row }">
                      <span class="grounding" v-if="Number(row.releaseStatus)">上架</span>
                      <span class="undercarriage" v-else>下架</span>
                    </template>
                  </vxe-table-column>
                  <vxe-table-column title="操作" width="230">
                      <template #default="{ row }">
                        <template v-if="$refs.xTable.isActiveByRow(row)">
                            <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
                        </template>
                        <template v-else>
                            <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
                            <vxe-button status="warning" @click="cutReleaseStatus(row)" :content="Number(row.releaseStatus) ? '下架' : '上架'"></vxe-button>
                            <vxe-button status="danger" @click="removeNotice(row)">删除</vxe-button>
                        </template>
                      </template>
                  </vxe-table-column>
                </vxe-table>
          </div>
      </div>
    </div>
</template>
  
<script>
  import API from '../service/api';
  import XEUtils from 'xe-utils'
  export default {
    name: 'hotWord',
    data() {
      return {
        loading: false,
        tableData: [],
      };
    },
    created() {
      this.getAllApp()
    },
    methods: {
        // 切换上下架状态
      cutReleaseStatus(row) {
        const _this = this
        _this.$confirm(`确定将“${row.id}”当前的“${Number(row.releaseStatus) ? '上架': '下架'}”修改为“${Number(row.releaseStatus) ? '下架': '上架'}”吗？, 是否继续?`, '修改公告上下架状态提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
            API.updateNotice({id: row.id, content: row.content, releaseStatus: Number(row.releaseStatus) ? 0 : 1}).then(res => {
                if(res.code == 200) {
                    _this.getAllApp()
                    _this.$message.success('修改成功');
                } else {
                    _this.$message.error(res.msg);
                }
            })
        }).catch(() => _this.$message.info(`已取消修改“${row.id}”`));
      },
      // 删除公告
      removeNotice(row) {
        const _this = this
        _this.$confirm(`确定删除id为“${row.id}”, 是否继续?`, '删除提示', {
          closeOnClickModal: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
            API.removeNotice({id: row.id}).then(res => {
                if(res.code == 200) {
                    _this.getAllApp()
                    _this.$message.success('删除成功');
                } else {
                    _this.$message.error(res.msg);
                }
            })
        }).catch(() => _this.$message.info(`已取消删除“${row.id}”`));
      },
      // 获取数据
      getAllApp() {
        this.loading = true
        API.getNoticePage({currentPage: 1, currentSize: 10}).then(res => {
            if(res.code == 200) {
                if(res.data.records && res.data.records.length ) {
                  this.tableData = res.data.records.sort((a, b) => a.sortNo - b.sortNo)
                } else {
                  this.tableData = []
                }
                this.loading = false
            } else {
                this.$message.error(res.msg)
                this.loading = false
            }
        })
      },
      // 编辑行
      editRowEvent(row) {
        const $table = this.$refs.xTable;
        $table.setActiveRow(row);
      },
      // 当前行保存
      saveRowEvent(row) {
        const _this = this
        this.loading = true
        // id为空是新增
        if(!row.id) {
            // content:内容。  releaseStatus: 0下架，1上架
            API.insertNotice({content: row.content, sortNo: row.sortNo, releaseStatus: 0}).then(res => {
                if(res.code == 200) {
                    
                    _this.getAllApp()
                } else {
                    _this.loading = false
                    _this.$message.error(res.msg)
                }
            })
        } else {
            // 存在id是修改
            API.updateNotice({id: row.id, content: row.content, sortNo: row.sortNo, releaseStatus: row.releaseStatus}).then(res => {
                if(res.code == 200) {
                    _this.getAllApp()
                    _this.$message.success('修改成功');
                } else {
                    _this.loading = false
                    _this.$message.error(res.msg)
                }
            })
        }
      },
      getNum(n) {
        return n < 10 ? '0' + n : n
      },
      async insertEvent(row) {
        var date = new Date() // 获取时间
        var year = date.getFullYear() // 获取年
        var month = date.getMonth() + 1 // 获取月
        var strDate = date.getDate() // 获取日
        var hour = date.getHours() // 获取小时
        var minute = date.getMinutes() // 获取分钟
        var second = date.getSeconds() // 获取秒
        var atPresentDate = `${year}-${this.getNum(month)}-${this.getNum(strDate)} ${this.getNum(hour)}:${this.getNum(minute)}:${this.getNum(second)}`
        let { row: newRow } = await this.$refs.xTable.insertAt({id: '', content: '', sortNo: '99', createTime: atPresentDate}, row)
        console.log(newRow)
        await this.$refs.xTable.setActiveCell(newRow, 'content')
      }
    }
  };
  </script>
  
  <style scope>
  .elDialog .el-dialog__header{
      background: white;
  }
  .el-popover {
    width: 203px !important;
    padding: 15px 15px 21px 20px !important;
  }
  .el-popover__title {
    margin: 5px 12px 20px 0 !important;
  }
  .el-dialog__footer {
    padding: 8px 20px 20px !important;
    text-align: right !important;
  }
  .iconSty {
    max-width: 45px;
    max-height: 45px;
    margin: 20px 0 0 15px;
  }
  .icon {
    max-width: 45px;
    max-height: 45px;
  }
  .knowledge {
    padding: 20px 0 0 20px;
  }
  .knowledge_box {
    width: 100%;
    background:#f0f2f5;
  }
  .knowledge_head {
    /* width: 100%; */
    height: 55px;
    padding: 17px 0 0 0;
    background:white;
    margin: 0 0 20px 0;
  }
  .knowledge_head_box {
    float: right;
    margin-right: 20px;
  }
  .knowledge_body {
    /* width: 100%; */
    /* height: 100px; */
    padding: 20px;
    background: white;
  }
  .grounding {
    padding: 3px 5px;
    background: green;
    color: white;
  }
  .undercarriage {
    padding: 3px 5px;
    background: red;
    color: white;
  }
  </style>
  